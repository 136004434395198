export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'fr',
  messages: {
    fr: {
      connexion: {
        dejaInscrit: 'Déjà inscrit ?',
        email: 'Email',
        password: 'Mot de passe',
        confirmPassword: 'Confirmer le mot de passe',
        ancienPassword: 'Ancien mot de passe',
        nouveauPassword: 'Nouveau mot de passe',
        rememberMe: 'Se souvenir de moi',
        login: 'Se connecter',
        createAccount: 'Créer un compte',
        newUser: 'Nouveau sur Copilot ?',
        immatriculation: 'Immatriculation',
        numDossier: 'Numéro de dossier',
      },
      routes: {
        '/vehicules': 'Vos véhicules',
        '/tutoriel': 'Bienvenue !',
        '/releve-kilometrique': 'Relevé kilométrique',
        '/prochaines-visites': 'Prochaines visites',
        '/que-faire/commentaire/revision': 'Revision',
        '/que-faire/commentaire/pneumatiques': 'Pneus',
        '/que-faire/commentaire/reparation': 'Réparation',
        '/que-faire/commentaire/carrosserie': 'Carrosserie',
        '/que-faire/commentaire/controlesTechniques': 'Contrôles techniques',
        '/que-faire/commentaire/sinistre': 'Sinistre',
        '/que-faire/commentaire/brisGlace': 'Bris de glace',
        '/que-faire/commentaire/depannageRemorquage': 'Depannage / Remorquage',
        '/que-faire/commentaire/vehiculeRemplacement': 'Véhicule de remplacement',
        '/que-faire/commentaire/carteCarburantTelebadge': 'Carte carburant / Télébadge',
        '/documents': 'Les documents du véhicule',
        '/documents/transmettre-document': 'Transmettre un document',
        '/mon-compte': 'Mon compte',
      },
      releve: {
        addReleve: 'Ajouter un relevé',
        source: {
          copilot: 'Copilot',
          mobipilot: 'Mobipilot',
          garage: 'Garage',
          kuantic: 'Kuantic',
          petrolier: 'Pétrolier',
          client: 'Client',
        },
      },
      visites: {
        motifs: {
          'CONTROLE TECHNIQUE': 'Contrôle technique',
          'CONTROLE TECHNIQUE PL': 'Contrôle technique PL',
          REVISION: 'Révision',
          'VISITE TECHNIQUE COMPLEMENTAIRE': 'Visite technique complémentaire',
          'CONTROLE TACHYGRAPHE': 'Contrôle tachygraphe',
          'CONTROLE LIMITEUR DE VITESSE': 'Contrôle limiteur de vitesse',
          'CONTROLE ETHYLOTEST': 'Contrôle éthylotest',
          'CONTRÔLE COMPACTION': 'Contrôle compaction',
          'CONTRÔLE LEVAGE': 'Contrôle levage',
        },
      },
      recherche: {
        prestation: {
          CONTROLE_TECHNIQUE: 'Contrôle technique',
          PNEUMATIQUE: 'Pneus',
          REVISION: 'Révision',
          REPARATION: 'Réparation',
          CARROSSERIE: 'Carrosserie',
          VITRAGE: 'Vitrage',
        },
      },
      queFaire: {
        prestation: {
          entretien: 'Entretien',
          revision: 'Revision',
          pneumatiques: 'Pneus',
          reparation: 'Réparation',
          carrosserie: 'Carrosserie',
          controlesTechniques: 'Contrôles techniques',
          sinistre: 'Sinistre',
          brisGlace: 'Bris de glace',
          depannageRemorquage: 'Depannage / Remorquage',
          vehiculeRemplacement: 'Véhicule de remplacement',
          carteCarburantTelebadge: 'Carte carburant / Télébadge',
        },
      },
      documents: {
        CARTE_GRISE: 'Carte grise',
        MEMO_VEHICULE_ASSURE: 'Memo véhicule assuré',
        CARTE_ACCREDITIVE: 'Carte accréditive',
        PV_CONTROLE_TECHNIQUE: 'Contrôle technique',
        PV_LIVRAISON: 'PV de livraison',
        PV_RESTITUTION: 'PV de restitution',
        CERTIFICAT_CARROSSAGE: 'Certificat carrossage',
        CERTIFICAT_CONFORMITE: 'Certificat conformité',
        CONSTAT_AMIABLE: 'Constat amiable',
        ETAT_DES_LIEUX: 'État de lieux',
        PHOTOS_VHC: 'Photos véhicule',
        PV_CONTRE_VISITE: 'PV contre-visite',
        PV_CONTROLE_POLLUTION: 'PV contrôle pollution',
        RAPPORT_EXPERTISE: 'Rapport expertise',
        RAPPORT_VGP: 'Rapport VGP',
        AUTRE: 'Autre',
      },
      messageErreur: {
        erreurGenerique: "Une erreur s'est produite, veuillez réessayer plus tard",
        mdpModifie: 'Votre mot de passe à bien été modifié',
        formulaireInvalide: 'Le formulaire est invalide',
        photosManquantes: 'Veuillez prendre les 5 photos demandées',
        captchaInvalide: 'Le captcha est invalide',
        erreurAuth: "Une erreur est survenue lors de l'authentification",
        invalidCredentials: "L'email ou le mot de passe est invalide",
        erreurRegistration: 'Une erreur est survenue lors de la création de votre compte',
        immatAttendue: 'Une immatriculation est attendue',
        erreurTelechargementDoc: "Une erreur s'est produite lors du téléchargement du document",
        erreurEnvoiDoc: "Une erreur s'est produite lors de l'envoi du document",
        erreurRecupFournisseurFavori: "Une erreur s'est produite lors de la récupération du fournisseur favori",
        erreurRecupFournisseurs: "Une erreur s'est produite lors de la récupération des fournisseurs",
        erreurAjoutFournisseurFavori: "Une erreur s'est produite lors de l'ajout du fournisseur favori",
        erreurNotationFournisseur: "Une erreur s'est produite lors de la notation du fournisseur",
        erreurRecupUtilisateur: "Une erreur s'est produite lors de la récupération de l'utilisateur",
        erreurRecupVehicules: "Une erreur s'est produite lors de la récupération des véhicules",
        erreurRecupInfosVehicule: 'Erreur lors de la récupération des informations du véhicule',
        erreurAjoutVehicule: "Une erreur s'est produite, vérifiez les informations saisies ou réessayez plus tard",
        erreurAjoutReleveKilometrique: "Erreur lors de l'ajout du relevé kilométrique",
        erreurAjoutEtatDesLieux: "Erreur lors de l'ajout de l'état des lieux",
        erreurInconnu: 'Inconnu',
      },
      messageWarning: {
        releveInferieur: 'Le relevé kilométrique est inférieur au dernier relevé, confirmez-vous cette saisie ?',
      },
      messageSucces: {
        mdpModifie: 'Votre mot de passe à bien été modifié',
        compteCree: 'Votre compte à bien été créé',
        vehiculeAjoute: 'Le véhicule a été ajouté avec succès',
        documentEnvoye: 'Le document a été envoyé avec succès',
        fournisseurAjoute: 'Le fournisseur a été ajouté avec succès',
        fournisseurNote: 'Le fournisseur a été noté avec succès',
        vehiculeSupprime: 'Le véhicule a été supprimé avec succès',
        majReleveKilometrique: 'Votre relevé kilométrique à bien été mis a jour',
        etatDesLieuxEnvoye: "L'état des lieux a été transmit avec succès",
      },
    },
  },
}));
